<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    VclList,
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      loading: true,
      errored: false,
      body: null,
    };
  },
  methods: {
    getCobrancas() {
      api.get("cobrancas").then((response) => {
        if (response.data.status == "success") {
          this.body = response.data.list;
        }
      })
        .catch((error) => {
          this.errored = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getCobrancas();
  },
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex justify-content-between mb-3">
      <div>
        <h3>Cobranças</h3>
        <p>Gerencie seus boletos de cobrança.</p>
      </div>
    </div>

    <div class="mb-3">
      <router-link tag="a" to="/cobranca/criar" class="btn btn-default mr-2">
        Criar
      </router-link>
      <router-link tag="a" to="/cobranca/importar" class="btn btn-default mr-2">
        Importar
      </router-link>
    </div>

    <div class="card">
      <div class="card-body">
        <div v-if="errored">
          Pedimos desculpas, não podemos recuperar informações neste momento. Por favor, tente novamente mais tarde.
        </div>
        <vcl-list v-else-if="loading" class="col-md-6"></vcl-list>
        <div v-else-if="body && body.length == 0" class="text-center">
          Nenhum favorecido encontrado.
        </div>
        <div v-else class="table-responsive">
          <table class="table table-nowrap table-style">
            <thead class="thead-light">
              <tr>
                <th></th>
                <th>Nome</th>
                <th>CPF/CNPJ</th>
                <th>Banco</th>
                <th>Agência</th>
                <th>Conta</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(td, index) in body" :key="index">
                <td>
                  <button class="btn btn-more">
                    <i class="fas fa-ellipsis-h"></i>
                  </button>
                </td>
                <td>{{ td.name }}</td>
                <td>{{ td.person_number }}</td>
                <td>{{ td.bank }}</td>
                <td>{{ td.agency }}-{{ td.agency_digit }}</td>
                <td>{{ td.account }}-{{ td.account_digit }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>